<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('lead.clientDetail')" class="px-5 py-3">
    <div v-if="client && client.uuid">
      <v-col cols="12" class="text-right add--button">
        <v-btn color="success" class="bottom--button mr-0" @click="openClientForm">
          {{ $t('client.btn.edit') }}
        </v-btn>
      </v-col>
      <client-detail-content :client="{ data: client }"></client-detail-content>
    </div>
    <div class="center--button" v-else>
      <v-btn color="success" class="mr-0" @click="openClientForm">
        {{ $t('client.btn.assign') }}
      </v-btn>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const ClientDetailContent = () =>
  import('@/components/transaction-management/lead/partials/client-detail-content');

export default {
  mixins: [HelperMixin],
  props: ['client'],
  components: {
    ClientDetailContent,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    openClientForm() {
      this.$modal.show('modal-client', {
        formType: 'ADD',
        client: this.client,
        commitString: 'lead/form/SET_CLIENT',
        dispatchString: 'lead/form/addClient',
        dispatchAfterString: 'lead/form/fetchBaseData',
      });
    },
  },
};
</script>
